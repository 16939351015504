import React from 'react'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { saveSignDetails, completeCallCenterForm, getCallCenterFormUserDetails, completeFTCallCenterForm } from '../../actions/formActions'
import { Form, Button } from 'react-bootstrap'
import FormContainer from '../Form/FormContainer'
import Spinner from 'react-bootstrap/Spinner'
import SignaturePad from 'react-signature-canvas'
import { CButton } from '@coreui/react'

const CallCenterProofForm = ({ history }) => {
    const urlParams = new URLSearchParams(history.location.search)
    const formId = urlParams.get('form_id')
    const [alldata, setData] = useState()
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [nationalInsNo, setNIN] = useState("")
    const [appliedCourse, setAppliedCourse] = useState("")
    const [prefContact, setPrefContact] = useState('')
    const [prefContact1, setPrefContact1] = useState('')
    const [prefContact2, setPrefContact2] = useState('')
    const [forSurveys, setForSurveys] = useState('')
    const [aboutCourse, setAboutCourse] = useState('')
    const [houseHoldMem, setHousehold1] = useState('')
    const [houseHoldMem2, setHousehold2] = useState('')
    const [singleHouse, setSingleHouse] = useState('')
    const [noneOfThese, setNoneOf] = useState('')
    const [confirmation, setConfirmation] = useState('')
    const [decl, setDeclare] = useState()
    const [proofList, setProofList] = useState()

    useEffect(async () => {
        const res = await getCallCenterFormUserDetails(formId)
        console.log(res.data)
        setProofList(res.proofs)
        setData(res.data);
        setFirstName(res.data.personalDetails.firstName)
        setLastName(res.data.personalDetails.lastName)
        setAppliedCourse(res.data.detailsFormData.appliedCourse)
        if (res?.response?.status === 503) history.push('/')
    }, [])

    function scrollTtoView() {
        document.getElementById('title-assessment').scrollIntoView({
            behavior: 'smooth'
        });
    }

    const dispatch = useDispatch()
    const [currentStep, setStep] = useState(0)
    const form = useSelector((state) => state.form)
    const { personalDetails, sign } = form
    const [idPic, setID] = useState()
    const [idPic1, setID1] = useState()
    const [evidence, setEvidence] = useState()
    const [evidence1, setEvidence1] = useState()
    const [evidence2, setEvidence2] = useState()
    const [id1, setId1] = useState()
    const [assessment, setAssessment] = useState()
    const [id2, setId2] = useState()
    const [id3, setId3] = useState()
    const [id4, setId4] = useState()
    const [signature, setTrimmed] = useState()
    const formCreate = useSelector((state) => state.final)
    const { final, success, error } = formCreate
    const [sigPad, setSigPad] = useState({})
    const [words, setWords] = useState()
    const [msg, setMsg] = useState()
    const [typeOfProof, setTypeOfProof] = useState()
    const [groupArray, setGroupArray] = useState([])

    const wordCounter = (string) => {
        return (string.split(" ").length - 1);
    }
    const changeValue = (value) => {
        setAssessment(value);
        setWords(wordCounter(value));
    }
    const clearSig = () => {
        sigPad.clear()
        setMsg(false)
    }
    const uploadImage = (file) => {
        const data = new FormData()
        data.append('file', file)
        data.append('upload_preset', 'evidence')
        data.append('cloud_name', 'my-free-course')
        return fetch('https://api.cloudinary.com/v1_1/my-free-course/image/upload', {
            method: 'POST',
            body: data,
        })
            .then((res) => res.json())
            .then((res) => {
                // console.log('URL FIles', res.url)
                return res.url
            })
    }
    const trim = () => {
        const response = sigPad.getTrimmedCanvas().toDataURL('image/png')
        setTrimmed(response)
        dispatch(saveSignDetails(response))
        setMsg(true)
    }
    const handleGroup = (id) => {
        if (groupArray.includes(id)) {
            let temp = groupArray.filter(function (item) {
                return item !== id
            })
            setGroupArray(temp)
        } else {
            let temp = [...groupArray]
            temp.push(id)
            setGroupArray(temp)
        }
    }

    let days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

    function isImage(file) {
        return file && file['type'].split('/')[0] === 'image'
    }
    // useEffect(() => {
    // 	if (success) {
    // 		history.push('/success')
    // 	}
    // }, [history, success])
    const handleNextValidation = () => {
        if (currentStep === 0) {
            console.log("step1")
            if (firstName && lastName && nationalInsNo) {
                console.log("step1 +0")
                if (alldata.employmentDetails.evidence === "Yes" && alldata.employmentDetails.evidence1 === "" && alldata.employmentDetails.evidence2 === "") {
                    if (evidence) {
                        console.log("step1 +1")
                        setStep(currentStep + 1);
                        scrollTtoView();
                    }
                    else {
                        toast.error('Submit all files')
                    }
                } else if (alldata.employmentDetails.evidence === "Yes" && alldata.employmentDetails.evidence1 === "Yes" && alldata.employmentDetails.evidence2 === "Yes") {
                    if (evidence) {
                        console.log("step1 +2")
                        setStep(currentStep + 1);
                        scrollTtoView();
                    }
                    else {
                        toast.error('Submit all files')
                    }
                } else if (alldata.employmentDetails.evidence === "Yes Less than" && alldata.employmentDetails.evidence1 === "Yes" && alldata.employmentDetails.evidence2 === "Yes") {

                    console.log("step1 +3")
                    setStep(currentStep + 1);
                    scrollTtoView();
                } else if (alldata.oppDetails.attachment1 === "Yes" || alldata.oppDetails.attachment2 === "Yes") {
                    if (id1) {

                        console.log("step1 +4")
                        setStep(currentStep + 1);
                        scrollTtoView();
                    }
                    else {
                        toast.error('Submit all files')
                    }
                }
                else if (alldata.oppDetails.attachment3 === "Yes") {
                    if (id3) {
                        setStep(currentStep + 1);
                        scrollTtoView();
                    }
                    else {
                        toast.error('Submit all files')
                    }
                }
                else {
                    setStep(currentStep + 1);
                }
            } else {
                toast.error('Complete All Fields')
            }
        } else if (currentStep === 1) {
            console.log(decl)
            if (decl) {
                setStep(currentStep + 1);
                scrollTtoView();
            } else {
                toast.error('Complete all fields')
            }
        }
    }
    const submitHandler = (e) => {
        e.preventDefault()
        document.getElementById('spinner-finalstep').style.display = 'block'
        window.stop()
        e.preventDefault()

        if (!idPic || !signature) {
            document.getElementById('spinner-finalstep').style.display = 'none'
            toast.error('Submit all files and signature')
            return
        }

        const data = new FormData()
        data.append('nationalInsNo', personalDetails.nationalInsNo)
        dispatch(saveSignDetails(signature))

        let images = [idPic, idPic1, evidence, evidence1, evidence2, id1, id2, id3, id4]
        let promises = []
        images.forEach((image) => promises.push(uploadImage(image)))
        Promise.all([...promises])
            .then(async (values) => {
                alldata.detailsFormData.PostCodeBL = alldata.personalDetails.postcode;
                alldata.employmentDetails.evidence = values[2] || '';
                alldata.employmentDetails.evidence2 = values[3] || '';
                alldata.employmentDetails.evidence3 = values[4] || '';
                alldata.oppDetails.attachment1 = values[5] || '';
                alldata.oppDetails.attachment2 = values[6] || '';
                alldata.oppDetails.attachment3 = values[7] || '';
                alldata.oppDetails.attachment4 = values[8] || '';
                alldata.personalDetails.firstName = firstName;
                alldata.personalDetails.lastName = lastName;
                alldata.personalDetails.nationalInsNo = nationalInsNo;
                alldata.qualificationDetails.assessment = assessment;
                alldata.declaration = {
                    prefContact: prefContact,
                    prefContact1: prefContact1,
                    prefContact2: prefContact2,
                    forSurveys: forSurveys,
                    aboutCourse: aboutCourse,
                    houseHoldMem: houseHoldMem,
                    houseHoldMem2: houseHoldMem2,
                    singleHouse: singleHouse,
                    noneOfThese: noneOfThese,
                    confirmation: confirmation
                }
                let res
                if (alldata.contractId === "663a8a4099f9f1ef02ed7208") {
                    res = await completeFTCallCenterForm(values[0] || '', values[1] || '', groupArray, typeOfProof, alldata)
                } else {
                    res = await completeCallCenterForm(values[0] || '', values[1] || '', groupArray, typeOfProof, alldata)
                }
                console.log(res)
                if (res)
                    history.push('/success')
            })
            .catch((err) => {
                console.log(err)
            })
    }

    function isImage(file) {
        return file && file['type'].split('/')[0] === 'image'
    }

    return (
        <>
            <div className='form-jumbotron'>
                <h3>LEARNER APPLICATION FORM</h3>
            </div>
            <center>
                <div className='container only-on-mobile'>
                    <img
                        src='https://res.cloudinary.com/my-free-course/image/upload/v1641932783/Site-pics/personal_page_images_nocdmn.png'
                        alt=''
                    />
                </div>
            </center>
            <FormContainer>
                <h3 id="title-assessment">
                    <b>Complete your course application</b>
                </h3>
                {/* <Form.Label className='form-notice'>
					The courses are fully funded by the UK Government’s Adult Education Budget.{' '}
					<br />
					As a condition of claiming the funding on your behalf, the college is required
					to carry out identity checks.
					<br />
					This is to make sure funding is going to genuine UK/EU citizens that qualify for
					free/funded training.
					<br />
					<p className='p-light'>
						*All details given are confidential and only used to claim the course
						funding.
					</p>
				</Form.Label> */}
                <br />

                <Form>
                    {(currentStep === 0) && <>
                        <b>Confirm your details</b><br />
                        <br />
                        <p>Applied Course: <b>{appliedCourse}</b></p>
                        <Form.Group controlId='firstName'>
                            <Form.Label>First Name </Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Enter First Name'
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId='lastName'>
                            <Form.Label>Last Name </Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Enter Last Name'
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill in this required field.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='nationalInsNo'>
                            <Form.Label>National Insurance Number</Form.Label>
                            <Form.Control
                                type='text'
                                pattern='^\s*[a-zA-Z]{2}(?:\s*\d\s*){6}[a-dA-D]{1}?\s*$'
                                value={nationalInsNo}
                                onChange={(e) => setNIN(e.target.value.toUpperCase().trim())}
                            ></Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                Please fill the required field / Please enter valid national insurance
                                number
                            </Form.Control.Feedback>
                        </Form.Group>
                        {alldata && <>
                            {(alldata.employmentDetails.evidence === "Yes" && alldata.employmentDetails.evidence1 === "" && alldata.employmentDetails.evidence2 === "") && <>
                                <br /><Form.Label className='form-notice'>
                                    Additional government funding is available to support the provision of qualifications to people who are on particular benefits. Please provide evidence of your receipt of benefits.
                                    <br />Acceptable documents include:<b> Benefits letter or screenshot of your benefits portal statement showing your benefit type and what benefits you are entitled to.</b>
                                </Form.Label>

                                <Form.Group controlId='evidence'>
                                    <Form.Control
                                        type='file'
                                        accept='image/*,application/pdf'
                                        required
                                        onChange={(e) => {
                                            setEvidence(e.target.files[0])
                                        }}
                                    ></Form.Control>
                                    {(evidence) && <>{evidence.name}</>}
                                    <Form.Control.Feedback type='invalid'>
                                        Please upload required file.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </>}
                            {((alldata.employmentDetails.evidence === "Yes Less than" && alldata.employmentDetails.evidence1 === "Yes" && alldata.employmentDetails.evidence2 === "Yes") || (alldata.employmentDetails.evidence === "Yes" && alldata.employmentDetails.evidence1 === "Yes" && alldata.employmentDetails.evidence2 === "Yes")) && <>
                                <br />
                                <b>Upload Your Employment documents</b><br /><br />
                                <Form.Label className='form-notice'>
                                    <p>
                                        Additional government funding is available to support the provision of qualifications to people who are in work but are earning below this amount. Please provide evidence of your low income.<br />
                                        Acceptable documents include: Payslip within the last 3 months, contract with employer stating your income.<br />
                                        The information provided is only used to access the government funding for your course. Your employer will not be contacted.<br />
                                    </p>
                                </Form.Label>
                                <Form.Group controlId='evidence'>
                                    <Form.Control
                                        type='file'
                                        accept='image/*,application/pdf'
                                        required
                                        onChange={(e) => {
                                            setEvidence(e.target.files[0])
                                        }}
                                    ></Form.Control>
                                    {(evidence) && <>{evidence.name}</>}
                                    <Form.Control.Feedback type='invalid'>
                                        Please upload required file.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId='evidence'>
                                    <Form.Label>Additional Evidence</Form.Label>
                                    <Form.Control
                                        type='file'
                                        accept='image/*,application/pdf'
                                        onChange={(e) => {
                                            setEvidence1(e.target.files[0])
                                        }}
                                    ></Form.Control>
                                    {(evidence1) && <>{evidence1.name}</>}
                                    <Form.Control.Feedback type='invalid'>
                                        Please upload required file.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId='evidence'>
                                    <Form.Label>Additional Evidence</Form.Label>
                                    <Form.Control
                                        type='file'
                                        accept='image/*,application/pdf'
                                        onChange={(e) => setEvidence2(e.target.files[0])}
                                    ></Form.Control>
                                    {(evidence2) && <>{evidence2.name}</>}
                                    <Form.Control.Feedback type='invalid'>
                                        Please upload required file.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </>}

                            {((alldata.oppDetails.attachment1 === "Yes" || alldata.oppDetails.attachment2 === "Yes")) && <>
                                <br />
                                <b>Upload Your Proof of residency</b><br /><br />

                                {(alldata.oppDetails.attachment1 === "Yes") && <>
                                    <Form.Group className='form-notice'>
                                        <Form.Label>
                                            Providing proof of your residency status for the last 3 years is a mandatory requirement in order to access funding. If this is not provided you will be deemed ineligible for funding and not permitted to access your chosen course.
                                            <br /><br />Please provide one of the below proof documents. Acceptable proof documents are:
                                            <br />
                                            1- Picture of your residence card (BRP Card)
                                            <br />
                                            2- Screenshot of your ‘share code’ from the government website below.
                                            <br /><br /><a href="https://www.gov.uk/view-prove-immigration-status">https://www.gov.uk/view-prove-immigration-status</a>
                                            <br /><br /><b>You will also need to provide proof of address dated within the last 3 months.</b>

                                        </Form.Label>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Group controlId='id1'>
                                            <Form.Label>
                                                <b>Proof of residency status</b>
                                            </Form.Label>
                                            <Form.Control
                                                type='file'
                                                accept='image/*,application/pdf'
                                                required
                                                onChange={(e) => setId1(e.target.files[0])}
                                            ></Form.Control>
                                            {(id1) && <>{id1.name}</>}
                                            <Form.Control.Feedback type='invalid'>
                                                Please upload required file.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId='id2'>
                                            <Form.Label>
                                                <b>Proof of address dated within last 3 months</b>
                                            </Form.Label>
                                            <Form.Control
                                                type='file'
                                                accept='image/*,application/pdf'
                                                onChange={(e) => setId2(e.target.files[0])}
                                            ></Form.Control>
                                            {(id2) && <>{id2.name}</>}
                                        </Form.Group>
                                    </Form.Group>
                                </>}
                            </>}
                            {(alldata.oppDetails.attachment3 === "Yes") &&
                                <>  <Form.Label className="form-notice">
                                    Providing proof of settled status for the last 3 years is a mandatory requirement in order to access funding. If this is not provided you will be deemed ineligible for funding and not permitted to access your chosen course.
                                    <br /><br />Please provide one of the below proof documents. Acceptable proof documents are:
                                    <br />
                                    1- Picture of your residence card (BRP Card)
                                    <br />
                                    2- Screenshot of your ‘share code’ from the government website below.
                                    <br /><br /><a href="https://www.gov.uk/view-prove-immigration-status">https://www.gov.uk/view-prove-immigration-status</a>
                                    <br /><br /><b>You will also need to provide proof of address dated within the last 3 months.</b>

                                </Form.Label>

                                    <Form.Group controlId='id3'>
                                        <Form.Label>
                                            <b>Proof of residency status</b>
                                        </Form.Label>
                                        <Form.Control
                                            type='file'
                                            accept='image/*,application/pdf'
                                            required
                                            onChange={(e) => setId3(e.target.files[0])}
                                        ></Form.Control>
                                        {(id3) && <>{id3.name}</>}
                                    </Form.Group>

                                    <Form.Group controlId='id4'>
                                        <Form.Label>
                                            <b>Proof of address dated within last 3 months</b>
                                        </Form.Label>
                                        <Form.Control
                                            type='file'
                                            accept='image/*,application/pdf'
                                            required
                                            onChange={(e) => setId4(e.target.files[0])}
                                        ></Form.Control>
                                    </Form.Group>
                                </>
                            }
                        </>}
                    </>
                    }

                    {(currentStep === 1) && <>
                        {(alldata.softEnglishTest === true) && <>
                            <Form.Group>
                                <Form.Label>Please briefly tell us why you wish to study this course or what you hope to achieve by completing the course. </Form.Label>
                                <Form.Control
                                    as='textarea'
                                    required
                                    rows={3}
                                    value={assessment}
                                    onChange={(e) => changeValue(e.target.value)}
                                ></Form.Control>
                                <Form.Control.Feedback type='invalid'>
                                    Please fill the required field.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <p style={{ float: "right", fontSize: "12px" }}>{words || "0"} Words</p><br />
                            <br />
                        </>}

                        <Form.Group controlId=''>
                            <b>Please tick which of the following apply (one or more may apply)</b>
                            <Form.Check
                                label='No household member is in employment, and the household includes one or more dependent children.'
                                type='checkbox'
                                value='houseHoldMem'
                                name='houseHoldMem'
                                onChange={(e) => setHousehold1(e.target.value)}
                                className='mr-2'
                            ></Form.Check>
                            <Form.Check
                                label='No household member is in employment, and the household does not include any dependent children.'
                                type='checkbox'
                                value='houseHoldMem2'
                                name='houseHoldMem2'
                                onChange={(e) => setHousehold2(e.target.value)}
                                className='mr-2'
                            ></Form.Check>
                            <Form.Check
                                label='Learner lives in a single adult household with dependent children.'
                                type='checkbox'
                                value='singleHouse'
                                name='singleHouse'
                                onChange={(e) => setSingleHouse(e.target.value)}
                                className='mr-2'
                            ></Form.Check>
                            <Form.Check
                                label='None of these statements apply.'
                                type='checkbox'
                                value='noneOfThese'
                                name='noneOfThese'
                                onChange={(e) => setNoneOf(e.target.value)}
                                className='mr-2'
                            ></Form.Check>
                            <Form.Check
                                label='I confirm that i wish to withhold this information.'
                                type='checkbox'
                                value='confirmation'
                                name='confirmation'
                                onChange={(e) => setConfirmation(e.target.value)}
                                className='mr-2'
                            ></Form.Check>

                            <br />
                            <br />
                            <Form.Label>
                                You can agree to be contacted by other third parties by ticking any of
                                the following boxes:{' '}
                            </Form.Label>
                            <Form.Check
                                type='checkbox'
                                label='About courses or learning opportunities'
                                name='aboutCourse'
                                value='aboutCourse'
                                onChange={(e) => setAboutCourse(e.target.value)}
                                className='mr-2'
                            ></Form.Check>

                            <Form.Check
                                type='checkbox'
                                label='For surveys and research'
                                name='forSurveys'
                                value='forSurveys'
                                onChange={(e) => setForSurveys(e.target.value)}
                                className='mr-2'
                            ></Form.Check>

                            <Form.Check
                                type='checkbox'
                                label='By Email'
                                name='email'
                                value='email'
                                onChange={(e) => setPrefContact(e.target.value)}
                                className='mr-2'
                            ></Form.Check>

                            <Form.Check
                                type='checkbox'
                                label='By Post'
                                name='post'
                                value='post'
                                onChange={(e) => setPrefContact1(e.target.value)}
                                className='mr-2'
                            ></Form.Check>

                            <Form.Check
                                type='checkbox'
                                label='By Phone'
                                name='phone'
                                value='phone'
                                onChange={(e) => setPrefContact2(e.target.value)}
                                className='mr-2'
                            ></Form.Check>
                        </Form.Group>

                        <Form.Group controlId='CHeckbox1'>


                            {alldata.contractId !== "663a8a4099f9f1ef02ed7208" ?
                                <Form.Label>
                                    I confirm that, to the best of my knowledge, the information given in this form is correct and
                                    complete.<br /><br />
                                    I understand that the course will be funded and delivered through one of the My Free Course
                                    partners. In the event that you do not qualify for the course to be fully funded, the My Free Course
                                    partners will waive the course fees, allowing you to still do the course for FREE.<br /><br />
                                    As the My Free Course partners cover all course fees whether fully or partially funded, if a learner
                                    enrols and fails to complete or withdraws early from the course the college or course provider  may charge up to but not limited to £135.00 fee to cover administration costs.<br /><br />
                                    The course provider will also supply a tutor to help support you on the course and there is the
                                    option to have an extension if you are unable to submit an assignment on time.
                                    <br /><br /></Form.Label>
                                : <>
                                    <Form.Label>
                                        I confirm, that to the best of my knowledge the information provided in this form is correct and complete.
<br/>
                                        I understand that the course will be funded and delivered through one of the My Free Course partners which adhere to the EFA and GLA funding policies.
                                        <br/>

                                        I understand that My Free Course will use the information that I and others on my behalf have provided for the purpose of my learning, as well as some external companies who work in partnership with My Free Course.
                                        <br/>

                                        I agree to My Free Course processing personal data contained within this form and the other documents completed as part of my enrolment for the course/training. I agree that such data can be processed for any purposes connected to my course or my health and safety, or for any other legitimate reason.
                                        <br/>

                                        I will inform My Free Course and the Partner providing my course/Training of any changes in my circumstances which may affect my eligibility between now and the day I start on the course.
                                        <br/>

                                        I have spoken to a member of the My Free Course team in which the learning program and the entry requirements have been outlined to me. I understand what the commitment is in undertaking the training and know what the days of attendance are.
                                        <br/>

                                        I am fully committed to completing any assignments, tests or course work required to achieve the qualification(s).
                                        <br/>

                                        Funding for this course comes from the Greater London Authority (GLA)  where the Mayor of London is responsible for the Adult Skills Funding (ASF) . The information provided by you in this form will be collected by the Education and Skills funding agency (EFSA).

                                        <br /><br /></Form.Label>
                                </>}
                            <Form.Check
                                type='checkbox'
                                label='Yes I understand'
                                name='decl'
                                value={decl}
                                required
                                feedback='You must agree before submitting.'
                                onChange={(e) => { setDeclare(e.currentTarget.checked) }}
                                className='mr-2'
                            ></Form.Check>

                        </Form.Group>
                    </>}
                    {(currentStep === 2) && <>
                        <Form.Group controlId='title'>

                            <h4>
                                <b>Final Step: ID Proof</b>
                            </h4>
                            <Form.Label className='form-notice'>
                                The courses provided are government funded.<br /><br />
                                As a condition of claiming the funding on your behalf, the college is required to carry out an ID check.<br /><br />
                                This is to make sure the course funding is going to a genuine UK or EU citizen.<br /><br />
                                <b>Acceptable ID proofs are: Passport, Birth certificates, Full drivers licence (not provisional or learners licence) or the top of a Utility bill or Bank statement (clearly showing your name, address and the provider).</b>
                                <p className='p-light'>
                                </p>
                            </Form.Label>
                            <img className="only-on-mobile" src="https://res.cloudinary.com/my-free-course/image/upload/w_600,c_fill/v1695660212/Site-pics/Untitled_design-8_jl7tp0_qbd1hw.jpg" style={{ width: "100%" }} />
                            <br /><br />
                            <Form.Label>
                                <span style={{ fontSize: '22px' }}>
                                    <b>1.</b>
                                </span>{' '}
                                <b>Please upload a photo of one valid ID Proof</b>
                                <br />This can be a Passport, Full drivers licence (not provisional or learners licence), Birth certificate or the top of a Utility bill(clearly showing your name, address, date of issue and the provider) or Bank statement (clearly showing your name, address and the provider).
                            </Form.Label>
                            <Form.Control
                                as='select'
                                required
                                value={typeOfProof}
                                onChange={(e) => setTypeOfProof(e.target.value)}
                            >
                                <option selected disabled value=''>
                                    Type of Proof
                                </option>
                                {/* FT college has different list */}
                                {alldata.contractId === "663a8a4099f9f1ef02ed7208" ?
                                    <>
                                        {["Passport", "Birth certificate", "Drivers licence"].map(x => <option value={x}>{x}</option>)}
                                    </> : <>
                                        {proofList.map(x => <option value={x}>{x}</option>)}
                                    </>}
                            </Form.Control>
                            <br />

                            <Form.Control
                                type='file'
                                required
                                placeholder='Enter address'
                                accept='image/*,application/pdf'
                                onChange={(e) => {
                                    setID(e.target.files[0])
                                }}
                            ></Form.Control>
                            {(idPic) && <>{idPic.name}</>}
                        </Form.Group>
                        {/* 
					<p>
						<span style={{ fontSize: '22px' }}>
							<b>2.</b>{' '}
						</span>
						<b> Optional (if name change applies) </b>
						<br />
						If your name on your ID is a different (surname/first) name to the one on
						your application, further evidence to support this change in the form of a
						marriage certificate or deed poll is required. If this does not apply to
						you, please continue to the signature section.
					</p>
					<Form.Group>
						<Form.Control
							type='file'
							accept='image/*,application/pdf'
							onChange={(e) => {
                                setID1(e.target.files[0])
							}}
						></Form.Control>
					</Form.Group> */}
                    </>}

                    {(currentStep === 2) && <>
                        {/* Contact Time Schedule */}
                        {/* Not showing for FT college */}
                        {alldata.contractId !== "663a8a4099f9f1ef02ed7208" && <><Form.Label ><b>Preferred Contact Times</b></Form.Label>
                            <p>Please confirm the best time for the course enrolment team to contact you, you can select multiple times.</p>
                            <div className="multi-select-time" id="multi-select-contact">
                                {days.map(day => <>
                                    <ul>
                                        <li className='contact-title-day'>
                                            <b>{day}</b>
                                        </li>
                                        <li>
                                            <CButton
                                                color={
                                                    groupArray.includes(day + ": 8am to 10am") ? 'success' : 'primary'
                                                }
                                                variant='outline'
                                                shape='square'
                                                size='sm'
                                                onClick={() => {
                                                    handleGroup(day + ": 8am to 10am")
                                                }}
                                            >
                                                8am to 10am
                                            </CButton>
                                        </li>
                                        <li >
                                            <CButton
                                                color={
                                                    groupArray.includes(day + ": 10am to 12pm") ? 'success' : 'primary'
                                                }
                                                variant='outline'
                                                shape='square'
                                                size='sm'
                                                onClick={() => {
                                                    handleGroup(day + ": 10am to 12pm")
                                                }}
                                            >
                                                10am to 12pm
                                            </CButton>
                                        </li>
                                        <li >
                                            <CButton
                                                color={
                                                    groupArray.includes(day + ": 12pm to 2pm") ? 'success' : 'primary'
                                                }
                                                variant='outline'
                                                shape='square'
                                                size='sm'
                                                onClick={() => {
                                                    handleGroup(day + ": 12pm to 2pm")
                                                }}
                                            >
                                                12pm to 2pm
                                            </CButton>
                                        </li>
                                        <li>
                                            <CButton
                                                color={
                                                    groupArray.includes(day + ": 2pm to 4pm") ? 'success' : 'primary'
                                                }
                                                variant='outline'
                                                shape='square'
                                                size='sm'
                                                onClick={() => {
                                                    handleGroup(day + ": 2pm to 4pm")
                                                }}
                                            >
                                                2pm to 4pm
                                            </CButton>
                                        </li>
                                        <li>
                                            <CButton
                                                color={
                                                    groupArray.includes(day + ": 4pm to 6pm") ? 'success' : 'primary'
                                                }
                                                variant='outline'
                                                shape='square'
                                                size='sm'
                                                onClick={() => {
                                                    handleGroup(day + ": 4pm to 6pm")
                                                }}
                                            >
                                                4pm to 6pm
                                            </CButton>

                                        </li>
                                        <li>
                                            <CButton
                                                color={
                                                    groupArray.includes(day + ": 6pm to 8pm") ? 'success' : 'primary'
                                                }
                                                variant='outline'
                                                shape='square'
                                                size='sm'
                                                onClick={() => {
                                                    handleGroup(day + ": 6pm to 8pm")
                                                }}
                                            >
                                                6pm to 8pm
                                            </CButton>
                                        </li>
                                    </ul>
                                </>)}
                            </div>
                            <br /><hr /></>}

                        <Form.Label as='legend'>Please Sign the Application</Form.Label>
                        <SignaturePad
                            id='canvas'
                            penColor='black'
                            className='mr-6'
                            onEnd={trim}
                            ref={(ref) => {
                                setSigPad(ref)
                            }}
                            canvasProps={{
                                width: 500,
                                height: 200,
                                className: 'sigCanvas',
                            }}
                        />
                        <br />
                        {/* <Button className='mr-5 next-Button-form' onClick={trim}>
						Sign{' '}
					</Button> */}
                        <Button className='back-Button-form' onClick={clearSig}>
                            Clear Signature
                        </Button>
                        <br />

                        <br />
                        {msg && <b>Your signature has been accepted. Please click the ‘Send Application’ button below.</b>}
                        <br />
                    </>}
                    <br />
                    {(currentStep >= 0 && currentStep < 2) && <><Button
                        onClick={() => handleNextValidation()}
                        className='next-Button-form'
                        style={{
                            width: '100px',
                            color: 'white',
                            float: 'right'
                        }}
                    >
                        {' '}
                        Next
                    </Button></>}
                    {(currentStep === 2) && <>
                        <Button
                            onClick={(e) => submitHandler(e)}
                            type='submit'
                            className='next-Button-form'
                            style={{
                                width: '100px',
                                color: 'white',
                                float: 'right'
                            }}
                        >
                            {' '}
                            Submit
                        </Button></>}
                    {(currentStep > 0 && currentStep < 3) && <><Button
                        onClick={() => {
                            setStep(currentStep - 1);
                            scrollTtoView();
                        }}
                        className='back-Button-form'
                        style={{
                            width: '100px',
                            color: 'white',
                            float: 'left'
                        }}
                    >
                        {' '}
                        Back
                    </Button></>
                    }
                </Form>
            </FormContainer>

            <div id='spinner-finalstep' style={{ display: 'none', zIndex: '999' }}>
                <center>
                    <Spinner animation='border' />
                </center>
            </div>
        </>
    )
}

export default CallCenterProofForm
