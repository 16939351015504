import React, { Component } from "react";
import { Helmet } from "react-helmet";
import ScrollToTopOnMount from "../common/ScrollToMount";

const TITLE = 'Contract Terms and Conditions'


const CollegeConditions = () => {
    return (
        <div className="container" id="responsive" style={{ marginTop: "10px" }}>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>

            <ScrollToTopOnMount />
            <h2>Partnered Colleges - Agreed Terms Of Service</h2>
            <br />
            <b>SERVICE 1 - Learner Find</b><br />
            <ul>
                <li>Learner Finder market for funded courses via our website - www.myfreecourse.co.uk</li>
                <li>The interested learner then completes an online course application form on our site</li>
                <li>The application is then mapped to your enrolment form, which we take in advance and code into the backend of our system</li>
                <li>When a completed application comes to us, we check to make sure it is completed correctly.</li>
                <li>The application (enrolment form & ID proof) is then approved and made available to your team to download so the applicant can be contacted.</li>

            </ul>
            <br />
            <b>Prior to Approval - We make sure the applicant has met the below</b><br />
            <ol>
                <li>The applicant has submitted an application form and indicated a desired course.</li>
                <li>The applicant has submitted one of the following ID proofs (UK or EU Passport, driver's license, birth certificate, or utility bill clearly showing provider, name, and address).</li>
                <li>The applicant has confirmed that they have a computer, laptop, or iPad required to access the course.</li>
                <li>They have confirmed that they have not done the chosen course previously.</li>
                <li>They have confirmed that they understand that the courses are split into manageable units and that if for any reason they cannot complete a unit they can request an extension from their tutor.</li>
                <li>The applicant has ticked the declaration (you can also provide your own declaration which can be added to the online form).</li>
                <li>They have confirmed they are aware the course provider may charge up to but not limited to £135.00 for them failing to complete the course or dropping out early to cover administration costs.</li>
                <li>The applicant is in an area that allows them to be eligible for funding (your target area). You can choose your target area and they are split as followed. National Non Devolved, WCMA, WECA, TVCA, SCRA, NTCA, WYCA, GLA, LCRA, GMCA, CPCA.</li>
            </ol>
            <br />
            <b>Once Approved - We go through the below follow-up process</b><br />
            <ol>
                <li>The applicant is called to confirm that your college will be contacting them to complete the enrolment process.</li>
                <li>They are given the name of the college that will be contacting them.</li>
                <li>They are then called after 3 weeks to make sure they were enrolled and also to make sure there were no issues (any issues raised are then fed back to your team).</li>
            </ol>
            <br />
            <b><i>Note: Our job is to get you an applicant that meets the criteria to get on a funded course. Any other documentation that relates to an uplift (proof of income or
                benefits), is down to the college.
            </i>
                <br />
                The above is done for a fixed price per application of £80+ VAT</b>
            <br />
            <br />
            <b>SERVICE 2 -  Learner Engagement Service</b><br />
            <p>For colleges that have challenges around internal resources or that need additional  resource support we offer a learner engagement service over an above our standard learner find service. This is geared towards improving your learner retention and completion rates. Engagement includes weekly contact to be made with learners until they have completed their course or for up to 12 weeks, via phone calls, emails and sms.</p>
            <p>The aim is to..</p>
            <ul>
                <li>Find out how the course is going and how they are progressing each week.</li>
                <li>Encourage engagement with the learning materials.</li>
                <li>Keep your team/tutors updated with any issues or learner requests.</li>
                <li>Act as a go-between if your team has any issues contacting the learner.</li>
            </ul>
            <br />
            <p>All of the above is aimed to...</p>
            <ul>
                <li>Increase learner engagement.</li>
                <li>Add support for learners.</li>
                <li>Reduce learner drop-offs.</li>
                <li>Increase completion rates.</li>
                <li>Reduce your internal customer service load.</li>
                <li>Aid you with any additional insight from learners to help tutors.</li>
            </ul>
            <br />
            <b>The above is done for a fixed price per application of £40+ VAT </b>
            <br />
            <br />
            <b>SERVICE 3 -  Learner Starts and Engagement</b><br />
            <p>The learner starts service is aimed at colleges who would like a guaranteed start as supposed to an application. We will find a learner for you and make sure they are enrolled and engaged.*
                <br />
                This will incorporate all of the service outlined in service 1 an 2 but will charge based on an applicant enrolling and completing the initial assessment and first unit question.
            </p>
            <br />
            <b>The above is done for a fixed price per application of £140+ VAT </b>
            <br />
            <br />
            <ol>
                <li>
                    <b>Contract & Cancellation</b>
                    <ol>
                        <li>Learner Finder is a trading style of Starch UK Ltd.</li>
                        <li>Learner Finder will provide a course application service based on the number of applications, time frame, and at the cost per application indicated on the order form.</li>
                        <li>An application is classed as someone who indicates that they want to enrol on a course by completing an online enrolment form in the agreed targeted locations and providing the relevant supporting documents (ID proof or proof of residency/share code if applicable). Acceptable ID Proofs can be: Valid passport, birth certificate, driver's licence, the top of a utility bill or bank statement clearly showing the provider/bank and your name and address (from the last 3 months). Please indicate on the order form which ID proofs you accept.</li>
                        <li>For the learner engagement service, we can provide on request communication between us and the learner if needed for your record to show communication for up to the 12 week period or the completion of the course. Whichever comes first.</li>
                        <li>Learner starts are classed as a learner who has gone through the application process detailed above and has been enrolled, completed the initial assessment and has answered at least one unit question.</li>
                        <li>Notice period for cancellation is 30 days. Please provide notice of cancellation in writing or via email to john@learnerfinder.co.uk</li>
                        <li>Learner Finder reserves the right to cancel its arrangement with you & will provide 10 days notice before the next month's cycle if it chooses to exercise this right.</li>
                        <li>If we are unable to meet the number of applications/learner starts as required in the time frame or at the cost originally agreed, we reserve the right to extend the time period, reduce the total amount of applications or cancel the agreement. In the case of the last two scenarios, a refund can be made, less the agreed cost per application, times the number of applications already delivered and minus our costs incurred for the setup (please see section 16 for what is involved in the setup). In any instance, we will discuss this with you to make sure you are aware of how we will proceed.</li>

                    </ol>
                </li>
                <li>
                    <b>Billing & Payment</b>
                    <ol>
                        <li>Once the order form is signed, we will send an invoice for the first month's payment. This is needed before the campaign and other component parts start.</li>
                        <li>If placing an order over 1,000 applications per month, a monthly split payment can be arranged. In such a case, you will be invoiced 50% upfront covering the month's applications and then again twice in the month covering the other 50% of what has been delivered. Example... at an agreed £80 per application with a 50% deposit paid in advance to commence and assuming a start of the month go live date. You will be invoiced £40 per application at the middle of the month covering the first half of the month and again at the end of the month covering the second half of the month.</li>
                        <li>All prices are in GBP and are subject to VAT.</li>
                        <li>Please send any billing queries to john@learnerfinder.co.uk.</li>
                    </ol>
                </li>
                <li>
                    <b>Queries & Complaints</b>
                    <ol>
                        <li>In the first instance, contact your account designated manager.</li>
                        <li>If you feel your complaint has not been resolved, please escalate to John Ogah – <a href="mailto:john@learnerfinder.co.uk">john@learnerfinder.co.uk</a>.</li>
                    </ol>
                </li>
                <li>
                    <b>Privacy & Security</b>
                    <ol>
                        <li>Learner Finder takes the privacy of its customers very seriously and is committed to protecting your privacy online.</li>
                        <li>Collecting personal information is necessary if we are to satisfy the expectations and requirements of our customers.</li>
                    </ol>
                </li>
                <li>
                    <b>Intellectual Property</b>
                    <ol>
                        <li>Pay Per Click: Learner Finder retains the IP rights on the data.</li>
                        <li>Analytics: Learner Finder retains the IP rights on the data.</li>
                        <li>Tag manager: Learner Finder retains the IP rights on the data.</li>
                        <li>Conversion Software: Learner Finder retains the rights on all Conversion Optimisation experiments & data.</li>
                        <li>Response Software: Learner Finder retains the rights on all data, and will hold this data for 90 days should you wish to return to us as a client.</li>
                        <li>Reports: All reports produced by Learner Finder are the copyright of Learner Finder.</li>
                    </ol>
                </li>
                <li>
                    <b>3rd Parties</b>
                    <ol>
                        <li>Occasionally Learner Finder may subcontract work to approved 3rd party suppliers.</li>
                        <li>Learner Finder reserves the right to assign its rights and delegate or sub-contract the provision of any of the services under its control to suitably qualified third parties.</li>
                        <li>Learner Finder website may contain hyperlinks to 3rd party websites. These 3rd party websites are not our responsibility, therefore we have no control over the information you may find via these 3rd party sites. Therefore Learner Finder shall not be liable for any detrimental content which may appear on one of these sites.</li>
                    </ol>
                </li>
                <li>
                    <b> Limitation of Liability</b>
                    <ol>
                        <li>
                            Learner Finder's entire liability and the Customer’s sole and exclusive remedy for any and all liability or claims in connection with or arising out of this Agreement or the existence, furnishing, functioning, or the Customer’s use of products or services provided under this Agreement, for any cause whatsoever, and regardless of the form or nature of the liability or claim, whether in contract or in tort, including, without limitation, claims regarding property damage (except in respect of injury to or death of any person for which no limit applies) due to the negligence of Learner Finder, shall be the Customer’s actual direct damages such as would be provable in a court of law, but shall in any event not exceed the Consultancy Fees paid by the customer.
                            The Customer agrees that the remedies available to the Customer in this Agreement are exclusive remedies, which the Customer agrees are reasonable, and all other remedies, statutory or otherwise, with respect to the subject matter hereof are hereby expressly waived by the Customer.
                        </li>
                        <li>
                            Save to the extent such liability cannot be excluded at law, in no event shall Learner Finder be liable under clause 7.1 for: Any incidental, indirect, special, economic, or consequential damages or loss whatsoever, including without limitation damages for business interruption, loss of business information, loss of software or software use, or loss of revenue or profit even if Learner Finder has been advised, knew, or should have known of the possibility of such damages or loss; or claims, demands, or actions by any party other than the Customer and other than under the terms of this Agreement; or any costs or expenses of whatever kind or nature incurred by the Customer.
                        </li>
                        <li>
                            Learner Finder shall not be liable in any way for any failure to perform its obligations under this Agreement or for any loss, damage, or delay incurred by the Customer resulting from circumstances beyond Learner Finder's reasonable control, provided that such circumstances are notified to the Customer in writing immediately upon discovery of the problem.
                        </li>
                    </ol>
                </li>
                <li>
                    <b>Insurance</b>
                    <ol>
                        <li>
                            Learner Finder shall have no liability to you for any delay in delivering services to you that is caused by any event or circumstance beyond its reasonable control.
                        </li>
                        <li>
                            The nature of Internet communications means that your communications may be susceptible to data corruption, interception, and delays. Learner Finder is not responsible for any detrimental reliance you place on its website and methods of communication.
                        </li>
                    </ol>
                </li>
                <li>
                    <b>UK Law</b>
                    <ol>
                        <li> This Agreement shall in all respects be governed by English law and the parties hereto submit to the non-exclusive jurisdiction of the English courts.</li>
                    </ol>
                </li>
                <li>
                    <b>Trademarks</b>
                    <ol>
                        <li>
                            Learner Finder is a trademark in its own right.
                        </li>
                        <li>
                            All trademarks reproduced on this website which are not the property of Learner Finder are acknowledged on the website.
                        </li>
                        <li>
                            All copyright and material on this website are the property of Learner Finder or are included with permission of the relevant owner.
                        </li>
                    </ol>
                </li>
                <li>
                    <b>Company Details</b>
                    <p>Registered office address: 71–75 Shelton Street, Covent Garden, London, WC2H 9JQ</p>
                </li>
                <li>
                    <b>Google & Facebook Terms & Conditions</b>
                    <ol>
                        <li>
                            The work we carry out is governed by the terms and conditions laid out by Google & Facebook.
                        </li>
                        <li>
                            You can review Google policies <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">here</a>.
                        </li>
                        <li>
                            You can review Facebook policies <a href="https://www.facebook.com/policies/ads/" target="_blank" rel="noopener noreferrer">here</a>.
                        </li>
                    </ol>
                </li>
                <li>
                    <b>Use of Logo & Client Name</b>
                    <ol>
                        <li>Learner Finder  may use your company name and logo in its marketing materials including our website and adverts.</li>
                    </ol>
                </li>
                <li>
                    <b>Google AdWords Disclosure</b>
                    <p>As a Google Third-Party, we are required to comply with Google’s Third-Party Policy. This policy requires that we provide our clients with a transparent, clear, and honest representation of our AdWords services. </p>
                </li>
                <li>
                    <b>Campaign Setup T&C’s</b>
                    <p>Campaigns are usually setup within 5 working days from the confirmation of the receipt of deposit funds. If for some reason this is not going to be the case, we will communicate any hold up in the process with you.<br />
                        Campaign Setup can include but is not limited to the following -<br />
                        Form Integration, Website/landing page build/amendment, Call, text & email automation, Sales funnel setup, Advertising campaign setup, Advertising creatives, Copy writing, Consultation time
                    </p>
                </li>
            </ol>

        </div>
    );
};

export default CollegeConditions;
