import React, { Component } from "react";
import CarouselSlider from "../common/carousel";
import About from "../common/aboutsus";
import CoursesCards from "../common/coursesCards";
import VideoSlider from "../common/videoSlider";
import EmailService from "../services/EmailService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CenterFocusWeakRounded } from "@material-ui/icons";
import { Helmet } from 'react-helmet'
import CookieConsent from "react-cookie-consent";


const LandingPage = () => {
    function scrollTtoCourse() {
        document.getElementById('courses').scrollIntoView({
            behavior: 'smooth'
        });
    }

    const TITLE = 'My Free Courses'

    return (
        <div>
            <Helmet>
                <title>{TITLE}</title>
                <meta name="facebook-domain-verification" content="8184eoi68ow2b4j4auij9061dmm0tg" />
            </Helmet>
            <CookieConsent location="bottom" cookieName="myAwesomeCookieName3" expires={999} overlay>
                This website uses cookies in order to give you the best possible visitor experience. Please <a href="/terms-conditions">click here</a> to view our <a href="/terms-conditions">privacy & cookies policy</a> and find out more information about how we use cookies and how you can manage them. By continuing to use our website you are consenting to such use of cookies.        </CookieConsent>
            <div className="d-sm-flex align-items-center justify-content-between w-100 landing-hero" >
                <div className="col-md-7 mx-auto mb-7 mb-sm-0 headline">
                    <h2 className="display-4 my-4 font-weight-bold landing-page-heading">Free online courses</h2>
                    <p className="text-secondary Subheadline">Adult courses with recognised qualifications delivered by leading colleges</p>
                    <p>Whether you're looking to increase your knowledge, make a career change or just learn something new, we have a range of courses with recognised qualifications to  suit your needs.  </p>
                    <br />
                    <a onKeyPress={scrollTtoCourse} onClick={scrollTtoCourse} className=" px-5 py-3 text-white mt-3 mt-sm-0 landing-page-btn" >Start a free course</a>
                </div>
                <div className="col-md-5 mx-auto mb-5 mb-sm-0">
                    <img src="https://res.cloudinary.com/my-free-course/image/upload/c_fit,h_500,w_500//v1627681757/Site-pics/1_eajtyi.jpg" alt="" />
                </div>
            </div>
            <div className="container">
                <div className="row social-logo-section1" >
                    <div className="col">
                        <img src="https://res.cloudinary.com/my-free-course/image/upload/v1695824750/Site-pics/Untitled-1_1_qbcxfj_qg8cze.png" style={{ width: "100%" }} alt="" />
                    </div>
                </div>
                <center>
                    <div className="row social-logo-section only-on-mobile" >
                        <img src="https://res.cloudinary.com/my-free-course/image/upload/w_600,c_fill/v1695660212/Site-pics/Untitled_design-8_jl7tp0_qbd1hw.jpg" alt="" />
                    </div>
                </center>
            </div>

            <div class="trustpilot-widget" data-locale="en-GB" data-template-id="54ad5defc6454f065c28af8b" data-businessunit-id="6452c1443652500ac4cf8caf" data-style-height="240px" data-style-width="100%" data-theme="light" data-stars="4,5" data-review-languages="en">
                <a href="https://uk.trustpilot.com/review/myfreecourse.co.uk" target="_blank" rel="noopener">Trustpilot</a>
            </div>
<br/>
<br/>

            <section className="services section-bg" id="services">
                <div className="container">
                    <header className="section-header">
                        <h3>How it Works</h3>

                        <p>We partner  with	 colleges across the UK to bring FREE/Funded courses with nationally recognised qualifications to all.</p>
                    </header>

                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="box">
                                <img
                                    style={{
                                        height: "80px",
                                        marginBottom: "15px",
                                    }}
                                    src="https://res.cloudinary.com/my-free-course/image/upload/c_fit,h_130,w_130//v1627689804/Site-pics/Opera_Snapshot_2021-07-31_050258_upwork-usw2-prod-file-storage-wp4.s3.us-west-2.amazonaws.com_vpyxqo.png"
                                />
                                <h4 className="title"><a href="">Step 1 </a></h4>

                                <p className="description">Choose from a wide range of available courses and apply online</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <div className="box">
                                <img
                                    style={{
                                        height: "80px",
                                        marginBottom: "15px",
                                    }}
                                    src="https://res.cloudinary.com/my-free-course/image/upload/c_fit,h_130,w_130//v1627689958/Site-pics/image_2021-07-31_050556_sd3vcf.png"
                                />

                                <h4 className="title"><a href="">Step 2 </a></h4>

                                <p className="description">Get connected with a college that offers your course fully funded and totally free</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <div className="box">
                                <img
                                    style={{
                                        height: "80px",
                                        marginBottom: "15px",
                                    }}
                                    src="https://res.cloudinary.com/my-free-course/image/upload/c_fit,h_130,w_130/v1627689978/Site-pics/image_2021-07-31_050617_knv0pz.png"
                                />

                                <h4 className="title"><a href="">Step 3 </a></h4>

                                <p className="description">Complete the course and gain a nationally recognised qualification</p>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col">
                            <center>
                                <br />
                                <a onKeyPress={scrollTtoCourse} style={{ width: "180px", margin: "auto" }} onClick={scrollTtoCourse} className=" px-5 py-3 text-white text-center mt-3 mt-sm-0 landing-page-btn" >See available courses</a>
                            </center>
                        </div>
                    </div>
                </div>
            </section>


            <section className="" id="learning">
                <div className="container">
                    <header className="section-header">
                        <h3>Your learning journey</h3>
                        <br /><br />
                    </header>

                    <div className="row">
                        <div className="col-md-6 col-lg-3 col-living-tabs">
                            <center>
                                <img
                                    style={{
                                        height: "130px",
                                        marginBottom: "15px",
                                    }}
                                    src="https://res.cloudinary.com/my-free-course/image/upload//c_fit,h_130,w_130/v1627697473/Site-pics/image_2021-07-31_071110_nx65hy.png"
                                />
                                <h4 className="title-lp">Simple online application </h4>
                            </center>
                            <br />
                            <p className="description">Our online application form allows for a quick and simple course application. <br /><br />
                                The form can be completed in under 10 minutes.
                            </p>

                        </div>

                        <div className="col-md-6 col-lg-3 col-living-tabs">
                            <center>
                                <img
                                    style={{
                                        height: "130px",
                                        marginBottom: "15px",
                                    }}
                                    src="https://res.cloudinary.com/my-free-course/image/upload/c_fit,h_130,w_130//v1627697518/Site-pics/image_2021-07-31_071156_jofgnx.png"
                                />
                                <h4 className="title-lp"> All courses are FREE!</h4>
                            </center>
                            <br />
                            <p className="description">Courses are funded by the UK Government’s Adult Skills Funding (ASF) or FREE from the college.
                                <br /><br />
                                (As long as you are 19+, live in England, have lived in the UK/EU for the last 3 years and haven't completed the course before).
                            </p>

                        </div>

                        <div className="col-md-6 col-lg-3 col-living-tabs">
                            <center>
                                <img
                                    style={{
                                        height: "130px",
                                        marginBottom: "15px",
                                    }}
                                    src="https://res.cloudinary.com/my-free-course/image/upload/c_fit,h_130,w_130//v1627697598/Site-pics/image_2021-07-31_071317_jcbqyy.png"
                                />
                                <br />
                                <h4 className="title-lp">Dedicated Tutor Support</h4>
                            </center>
                            <br />
                            <p className="description">Receive a dedicated tutor to help support your learning journey.<br /><br />
                                They will be available via email and phone to provide guidance and feedback on submitted units.
                            </p>

                        </div>

                        <div className="col-md-6 col-lg-3 col-living-tabs">
                            <center>
                                <img
                                    style={{
                                        height: "130px",
                                        marginBottom: "15px",
                                    }}
                                    src="https://res.cloudinary.com/my-free-course/image/upload/c_fit,h_130,w_130//v1627697569/Site-pics/image_2021-07-31_071247_emdsn7.png"
                                />
                                <h4 className="title-lp">TQUK  Accredited Qualification </h4>
                            </center>
                            <br />
                            <p className="description">All courses come with a nationally recognised Level 2 qualification. <br /><br />
                                They are accredited by TQUK and regulated by Ofqual.
                            </p>

                        </div>
                    </div>
                </div>
            </section>

            <div id="courses">
                <CoursesCards />
            </div>
        </div>
    );
};

export default LandingPage;
