export const nationalitiesWithCodes = [
    { label: "British", value: "GB" },
    { label: "Afghan", value: "AF" },
    { label: "Albanian", value: "AL" },
    { label: "Algerian", value: "DZ" },
    { label: "American", value: "US" },
    { label: "Andorran", value: "AD" },
    { label: "Angolan", value: "AO" },
    { label: "Antiguans", value: "AG" },
    { label: "Argentinean", value: "AR" },
    { label: "Armenian", value: "AM" },
    { label: "Australian", value: "AU" },
    { label: "Austrian", value: "AT" },
    { label: "Azerbaijani", value: "AZ" },
    { label: "Bahamian", value: "BS" },
    { label: "Bahraini", value: "BH" },
    { label: "Bangladeshi", value: "BD" },
    { label: "Barbadian", value: "BB" },
    { label: "Barbudans", value: "AG" },
    { label: "Batswana", value: "BW" },
    { label: "Belarusian", value: "BY" },
    { label: "Belgian", value: "BE" },
    { label: "Belizean", value: "BZ" },
    { label: "Beninese", value: "BJ" },
    { label: "Bhutanese", value: "BT" },
    { label: "Bolivian", value: "BO" },
    { label: "Bosnian", value: "BA" },
    { label: "Brazilian", value: "BR" },
    { label: "Bruneian", value: "BN" },
    { label: "Bulgarian", value: "BG" },
    { label: "Burkinabe", value: "BF" },
    { label: "Burmese", value: "MM" },
    { label: "Burundian", value: "BI" },
    { label: "Cambodian", value: "KH" },
    { label: "Cameroonian", value: "CM" },
    { label: "Canadian", value: "CA" },
    { label: "Cape Verdean", value: "CV" },
    { label: "Central African", value: "CF" },
    { label: "Chadian", value: "TD" },
    { label: "Chilean", value: "CL" },
    { label: "Chinese", value: "CN" },
    { label: "Colombian", value: "CO" },
    { label: "Comoran", value: "KM" },
    { label: "Congolese", value: "CG" },
    { label: "Costa Rican", value: "CR" },
    { label: "Croatian", value: "HR" },
    { label: "Cuban", value: "CU" },
    { label: "Cypriot", value: "CY" },
    { label: "Czech", value: "CZ" },
    { label: "Danish", value: "DK" },
    { label: "Djibouti", value: "DJ" },
    { label: "Dominican", value: "DO" },
    { label: "Dutch", value: "NL" },
    { label: "East Timorese", value: "TL" },
    { label: "Ecuadorean", value: "EC" },
    { label: "Egyptian", value: "EG" },
    { label: "Emirian", value: "AE" },
    { label: "Equatorial Guinean", value: "GQ" },
    { label: "Eritrean", value: "ER" },
    { label: "Estonian", value: "EE" },
    { label: "Ethiopian", value: "ET" },
    { label: "Fijian", value: "FJ" },
    { label: "Filipino", value: "PH" },
    { label: "Finnish", value: "FI" },
    { label: "French", value: "FR" },
    { label: "Gabonese", value: "GA" },
    { label: "Gambian", value: "GM" },
    { label: "Georgian", value: "GE" },
    { label: "German", value: "DE" },
    { label: "Ghanaian", value: "GH" },
    { label: "Greek", value: "GR" },
    { label: "Grenadian", value: "GD" },
    { label: "Guatemalan", value: "GT" },
    { label: "Guinea-Bissauan", value: "GW" },
    { label: "Guinean", value: "GN" },
    { label: "Guyanese", value: "GY" },
    { label: "Haitian", value: "HT" },
    { label: "Herzegovinian", value: "BA" },
    { label: "Honduran", value: "HN" },
    { label: "Hungarian", value: "HU" },
    { label: "Icelander", value: "IS" },
    { label: "Indian", value: "IN" },
    { label: "Indonesian", value: "ID" },
    { label: "Iranian", value: "IR" },
    { label: "Iraqi", value: "IQ" },
    { label: "Irish", value: "IE" },
    { label: "Israeli", value: "IL" },
    { label: "Italian", value: "IT" },
    { label: "Ivorian", value: "CI" },
    { label: "Jamaican", value: "JM" },
    { label: "Japanese", value: "JP" },
    { label: "Jordanian", value: "JO" },
    { label: "Kazakhstani", value: "KZ" },
    { label: "Kenyan", value: "KE" },
    { label: "Kittian and Nevisian", value: "KN" },
    { label: "Kuwaiti", value: "KW" },
    { label: "Kyrgyz", value: "KG" },
    { label: "Laotian", value: "LA" },
    { label: "Latvian", value: "LV" },
    { label: "Lebanese", value: "LB" },
    { label: "Liberian", value: "LR" },
    { label: "Libyan", value: "LY" },
    { label: "Liechtensteiner", value: "LI" },
    { label: "Lithuanian", value: "LT" },
    { label: "Luxembourger", value: "LU" },
    { label: "Macedonian", value: "MK" },
    { label: "Malagasy", value: "MG" },
    { label: "Malawian", value: "MW" },
    { label: "Malaysian", value: "MY" },
    { label: "Maldivan", value: "MV" },
    { label: "Malian", value: "ML" },
    { label: "Maltese", value: "MT" },
    { label: "Marshallese", value: "MH" },
    { label: "Mauritanian", value: "MR" },
    { label: "Mauritian", value: "MU" },
    { label: "Mexican", value: "MX" },
    { label: "Micronesian", value: "FM" },
    { label: "Moldovan", value: "MD" },
    { label: "Monacan", value: "MC" },
    { label: "Mongolian", value: "MN" },
    { label: "Moroccan", value: "MA" },
    { label: "Mosotho", value: "LS" },
    { label: "Motswana", value: "BW" },
    { label: "Mozambican", value: "MZ" },
    { label: "Namibian", value: "NA" },
    { label: "Nauruan", value: "NR" },
    { label: "Nepalese", value: "NP" },
    { label: "New Zealander", value: "NZ" },
    { label: "Ni-Vanuatu", value: "VU" },
    { label: "Nicaraguan", value: "NI" },
    { label: "Nigerian", value: "NG" },
    { label: "North Korean", value: "KP" },
    { label: "Northern Irish", value: "GB" },
    { label: "Norwegian", value: "NO" },
    { label: "Omani", value: "OM" },
    { label: "Pakistani", value: "PK" },
    { label: "Palauan", value: "PW" },
    { label: "Panamanian", value: "PA" },
    { label: "Papua New Guinean", value: "PG" },
    { label: "Paraguayan", value: "PY" },
    { label: "Peruvian", value: "PE" },
    { label: "Polish", value: "PL" },
    { label: "Portuguese", value: "PT" },
    { label: "Qatari", value: "QA" },
    { label: "Romanian", value: "RO" },
    { label: "Russian", value: "RU" },
    { label: "Rwandan", value: "RW" },
    { label: "Saint Lucian", value: "LC" },
    { label: "Salvadoran", value: "SV" },
    { label: "Samoan", value: "WS" },
    { label: "San Marinese", value: "SM" },
    { label: "Sao Tomean", value: "ST" },
    { label: "Saudi", value: "SA" },
    { label: "Scottish", value: "GB" },
    { label: "Senegalese", value: "SN" },
    { label: "Serbian", value: "RS" },
    { label: "Seychellois", value: "SC" },
    { label: "Sierra Leonean", value: "SL" },
    { label: "Singaporean", value: "SG" },
    { label: "Slovakian", value: "SK" },
    { label: "Slovenian", value: "SI" },
    { label: "Solomon Islander", value: "SB" },
    { label: "Somali", value: "SO" },
    { label: "South African", value: "ZA" },
    { label: "South Korean", value: "KR" },
    { label: "Spanish", value: "ES" },
    { label: "Sri Lankan", value: "LK" },
    { label: "Sudanese", value: "SD" },
    { label: "Surinamer", value: "SR" },
    { label: "Swazi", value: "SZ" },
    { label: "Swedish", value: "SE" },
    { label: "Swiss", value: "CH" },
    { label: "Syrian", value: "SY" },
    { label: "Taiwanese", value: "TW" },
    { label: "Tajik", value: "TJ" },
    { label: "Tanzanian", value: "TZ" },
    { label: "Thai", value: "TH" },
    { label: "Togolese", value: "TG" },
    { label: "Tongan", value: "TO" },
    { label: "Trinidadian or Tobagonian", value: "TT" },
    { label: "Tunisian", value: "TN" },
    { label: "Turkish", value: "TR" },
    { label: "Tuvaluan", value: "TV" },
    { label: "Ugandan", value: "UG" },
    { label: "Ukrainian", value: "UA" },
    { label: "Uruguayan", value: "UY" },
    { label: "Uzbekistani", value: "UZ" },
    { label: "Venezuelan", value: "VE" },
    { label: "Vietnamese", value: "VN" },
    { label: "Welsh", value: "GB" },
    { label: "Yemenite", value: "YE" },
    { label: "Zambian", value: "ZM" },
    { label: "Zimbabwean", value: "ZW" },
];


export const countriesWithCodes = [
    { name: "Afghanistan", code: "AF" },
    { name: "Albania", code: "AL" },
    { name: "Algeria", code: "DZ" },
    { name: "Andorra", code: "AD" },
    { name: "Angola", code: "AO" },
    { name: "Antigua and Barbuda", code: "AG" },
    { name: "Argentina", code: "AR" },
    { name: "Armenia", code: "AM" },
    { name: "Australia", code: "AU" },
    { name: "Austria", code: "AT" },
    { name: "Azerbaijan", code: "AZ" },
    { name: "Bahamas", code: "BS" },
    { name: "Bahrain", code: "BH" },
    { name: "Bangladesh", code: "BD" },
    { name: "Barbados", code: "BB" },
    { name: "Belarus", code: "BY" },
    { name: "Belgium", code: "BE" },
    { name: "Belize", code: "BZ" },
    { name: "Benin", code: "BJ" },
    { name: "Bhutan", code: "BT" },
    { name: "Bolivia", code: "BO" },
    { name: "Bosnia and Herzegovina", code: "BA" },
    { name: "Botswana", code: "BW" },
    { name: "Brazil", code: "BR" },
    { name: "Brunei", code: "BN" },
    { name: "Bulgaria", code: "BG" },
    { name: "Burkina Faso", code: "BF" },
    { name: "Burundi", code: "BI" },
    { name: "Cabo Verde", code: "CV" },
    { name: "Cambodia", code: "KH" },
    { name: "Cameroon", code: "CM" },
    { name: "Canada", code: "CA" },
    { name: "Central African Republic", code: "CF" },
    { name: "Chad", code: "TD" },
    { name: "Chile", code: "CL" },
    { name: "China", code: "CN" },
    { name: "Colombia", code: "CO" },
    { name: "Comoros", code: "KM" },
    { name: "Congo", code: "CG" },
    { name: "Congo, Democratic Republic of the", code: "CD" },
    { name: "Costa Rica", code: "CR" },
    { name: "Croatia", code: "HR" },
    { name: "Cuba", code: "CU" },
    { name: "Cyprus", code: "CY" },
    { name: "Czech Republic", code: "CZ" },
    { name: "Denmark", code: "DK" },
    { name: "Djibouti", code: "DJ" },
    { name: "Dominica", code: "DM" },
    { name: "Dominican Republic", code: "DO" },
    { name: "Ecuador", code: "EC" },
    { name: "Egypt", code: "EG" },
    { name: "El Salvador", code: "SV" },
    { name: "Equatorial Guinea", code: "GQ" },
    { name: "Eritrea", code: "ER" },
    { name: "Estonia", code: "EE" },
    { name: "Eswatini", code: "SZ" },
    { name: "England", code: "XF" },
    { name: "Ethiopia", code: "ET" },
    { name: "Fiji", code: "FJ" },
    { name: "Finland", code: "FI" },
    { name: "France", code: "FR" },
    { name: "Gabon", code: "GA" },
    { name: "Gambia", code: "GM" },
    { name: "Georgia", code: "GE" },
    { name: "Germany", code: "DE" },
    { name: "Ghana", code: "GH" },
    { name: "Greece", code: "GR" },
    { name: "Greenland", code: "GL" },
    { name: "Grenada", code: "GD" },
    { name: "Guatemala", code: "GT" },
    { name: "Guinea", code: "GN" },
    { name: "Guinea-Bissau", code: "GW" },
    { name: "Guyana", code: "GY" },
    { name: "Haiti", code: "HT" },
    { name: "Honduras", code: "HN" },
    { name: "Hungary", code: "HU" },
    { name: "Iceland", code: "IS" },
    { name: "India", code: "IN" },
    { name: "Indonesia", code: "ID" },
    { name: "Iran", code: "IR" },
    { name: "Iraq", code: "IQ" },
    { name: "Ireland", code: "IE" },
    { name: "Israel", code: "IL" },
    { name: "Italy", code: "IT" },
    { name: "Jamaica", code: "JM" },
    { name: "Japan", code: "JP" },
    { name: "Jordan", code: "JO" },
    { name: "Kazakhstan", code: "KZ" },
    { name: "Kenya", code: "KE" },
    { name: "Kiribati", code: "KI" },
    { name: "Korea, North", code: "KP" },
    { name: "Korea, South", code: "KR" },
    { name: "Kuwait", code: "KW" },
    { name: "Kyrgyzstan", code: "KG" },
    { name: "Laos", code: "LA" },
    { name: "Latvia", code: "LV" },
    { name: "Lebanon", code: "LB" },
    { name: "Lesotho", code: "LS" },
    { name: "Liberia", code: "LR" },
    { name: "Libya", code: "LY" },
    { name: "Liechtenstein", code: "LI" },
    { name: "Lithuania", code: "LT" },
    { name: "Luxembourg", code: "LU" },
    { name: "Madagascar", code: "MG" },
    { name: "Malawi", code: "MW" },
    { name: "Malaysia", code: "MY" },
    { name: "Maldives", code: "MV" },
    { name: "Mali", code: "ML" },
    { name: "Malta", code: "MT" },
    { name: "Marshall Islands", code: "MH" },
    { name: "Martinique", code: "MQ" },
    { name: "Mauritania", code: "MR" },
    { name: "Mauritius", code: "MU" },
    { name: "Mayotte", code: "YT" },
    { name: "Mexico", code: "MX" },
    { name: "Micronesia", code: "FM" },
    { name: "Moldova", code: "MD" },
    { name: "Monaco", code: "MC" },
    { name: "Mongolia", code: "MN" },
    { name: "Montenegro", code: "ME" },
    { name: "Montserrat", code: "MS" },
    { name: "Morocco", code: "MA" },
    { name: "Mozambique", code: "MZ" },
    { name: "Myanmar (formerly Burma)", code: "MM" },
    { name: "Namibia", code: "NA" },
    { name: "Nauru", code: "NR" },
    { name: "Nepal", code: "NP" },
    { name: "Netherlands", code: "NL" },
    { name: "New Caledonia", code: "NC" },
    { name: "New Zealand", code: "NZ" },
    { name: "Nicaragua", code: "NI" },
    { name: "Niger", code: "NE" },
    { name: "Nigeria", code: "NG" },
    { name: "Niue", code: "NU" },
    { name: "Norfolk Island", code: "NF" },
    { name: "Northern Mariana Islands", code: "MP" },
    { name: "Norway", code: "NO" },
    { name: "Oman", code: "OM" },
    { name: "Pakistan", code: "PK" },
    { name: "Palau", code: "PW" },
    { name: "Palestine State", code: "PS" },
    { name: "Panama", code: "PA" },
    { name: "Papua New Guinea", code: "PG" },
    { name: "Paraguay", code: "PY" },
    { name: "Peru", code: "PE" },
    { name: "Philippines", code: "PH" },
    { name: "Poland", code: "PL" },
    { name: "Portugal", code: "PT" },
    { name: "Qatar", code: "QA" },
    { name: "Romania", code: "RO" },
    { name: "Russia", code: "RU" },
    { name: "Rwanda", code: "RW" },
    { name: "Saint Barthélemy", code: "BL" },
    { name: "Saint Helena", code: "SH" },
    { name: "Saint Kitts and Nevis", code: "KN" },
    { name: "Saint Lucia", code: "LC" },
    { name: "Saint Martin", code: "MF" },
    { name: "Saint Pierre and Miquelon", code: "PM" },
    { name: "Saint Vincent and the Grenadines", code: "VC" },
    { name: "Samoa", code: "WS" },
    { name: "San Marino", code: "SM" },
    { name: "Sao Tome and Principe", code: "ST" },
    { name: "Saudi Arabia", code: "SA" },
    { name: "Senegal", code: "SN" },
    { name: "Serbia", code: "RS" },
    { name: "Seychelles", code: "SC" },
    { name: "Sierra Leone", code: "SL" },
    { name: "Singapore", code: "SG" },
    { name: "Slovakia", code: "SK" },
    { name: "Slovenia", code: "SI" },
    { name: "Solomon Islands", code: "SB" },
    { name: "Somalia", code: "SO" },
    { name: "South Africa", code: "ZA" },
    { name: "South Sudan", code: "SS" },
    { name: "Spain", code: "ES" },
    { name: "Sri Lanka", code: "LK" },
    { name: "Sudan", code: "SD" },
    { name: "Suriname", code: "SR" },
    { name: "Sweden", code: "SE" },
    { name: "Switzerland", code: "CH" },
    { name: "Syria", code: "SY" },
    { name: "Taiwan", code: "TW" },
    { name: "Tajikistan", code: "TJ" },
    { name: "Tanzania", code: "TZ" },
    { name: "Thailand", code: "TH" },
    { name: "Timor-Leste", code: "TL" },
    { name: "Togo", code: "TG" },
    { name: "Tonga", code: "TO" },
    { name: "Trinidad and Tobago", code: "TT" },
    { name: "Tunisia", code: "TN" },
    { name: "Turkey", code: "TR" },
    { name: "Turkmenistan", code: "TM" },
    { name: "Turks and Caicos Islands", code: "TC" },
    { name: "Tuvalu", code: "TV" },
    { name: "Uganda", code: "UG" },
    { name: "Ukraine", code: "UA" },
    { name: "United Arab Emirates", code: "AE" },
    { name: "United Kingdom", code: "GB" },
    { name: "United States", code: "US" },
    { name: "Uruguay", code: "UY" },
    { name: "Uzbekistan", code: "UZ" },
    { name: "Vanuatu", code: "VU" },
    { name: "Vatican City", code: "VA" },
    { name: "Venezuela", code: "VE" },
    { name: "Vietnam", code: "VN" },
    { name: "Wallis and Futuna", code: "WF" },
    { name: "Western Sahara", code: "EH" },
    { name: "Yemen", code: "YE" },
    { name: "Zambia", code: "ZM" },
    { name: "Zimbabwe", code: "ZW" },
  ];
  