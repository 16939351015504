import React from "react";
import ScrollToTopOnMount from "../common/ScrollToMount";

import { Helmet } from 'react-helmet'


const TITLE = 'My Free Courses'
const YorkShirePolicy = () => {
    return (
        <div className="container" id="responsive" style={{ marginTop: "10px" }}>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>

            <ScrollToTopOnMount />

            <h2>Heart Of YorkShire Learner Privacy Notice</h2>

            <h3>Further Education Enrolment Form: Terms & Conditions 2024/2025</h3>
           
            <hr />

            <section>
                <h4>Section A: Guidance</h4>
                <p>Before you enrol on your programme of study at The College, you should have received guidance and assessment from college staff to ensure that:</p>
                <ul>
                    <li>You have considered all available options in order to achieve your goals.</li>
                    <li>You understand where this course might lead and how it fits with your future plans.</li>
                    <li>You understand how you will be assessed on the course, and what you will need to achieve in order to progress.</li>
                    <li>You will not find the course too easy or too difficult and you have the correct entry qualifications or other requirements.</li>
                    <li>You can use any appropriate experience or qualifications to count towards your course(s).</li>
                    <li>If you are under 19 and attending a full-time Study Programme, you will be expected to attend Maths and English qualification(s) where appropriate.</li>
                    <li>You understand that where the course leads to a qualification you will be registered with the awarding body.</li>
                    <li>You will receive appropriate support (practical/tutorial) to help you complete the course(s).</li>
                    <li>You have been made aware of all costs involved and any financial help available.</li>
                    <li>If your course is in Social Care, Childcare, or Teacher Training, you will be expected to provide proof of a satisfactory enhanced disclosure from the Disclosure and Barring Service (DBS).</li>
                </ul>
                <p>The guidance you received could be through a short interview, course information staff, interview with a tutor, leaflets, career staff, telephone, or letter.</p>
            </section>

            <hr />

            <section>
                <h4>Section B: Terms of Enrolment</h4>
                <p>As a student at The College:</p>
                <ul>
                    <li>You will sign the learner agreement declaration on the enrolment form to confirm that you understand the Terms & Conditions of your enrolment. This will form part of your learning agreement.</li>
                    <li>You will abide by College policies and procedures including the Student Code of Conduct listed on our website: <a href="https://bit.ly/3zCFjTi">https://bit.ly/3zCFjTi</a> or from Student Services.</li>
                </ul>
                <p>You understand that:</p>
                <ul>
                    <li>By enrolling, you have reserved a place on the course and as such, the full fees for which you are responsible remain payable if you withdraw from your course after attending for more than 2 weeks.</li>
                    <li>If you have an Advanced Learner Loan and then withdraw from your course, you will be charged the full fee less any loan payments made on your behalf.</li>
                    <li>If fees are to be paid by a third party and payment is not honored, then you will become responsible for the full fees due.</li>
                    <li>The College follows debt recovery procedures in the event of non-payment of fees, which may involve passing your details to external debt collection agencies.</li>
                    <li>If your course leads to a qualification, you agree to being registered with the appropriate Awarding Body, and failure to meet attendance or coursework requirements could result in registration being canceled.</li>
                    <li>Certification fees are non-refundable, and you may be charged for resits.</li>
                    <li>If you have received a fee concession and withdraw from the course/exam without a valid medical reason, you may be charged the full certification fee.</li>
                    <li>If applicable, The College reserves the right to refuse admission to a course if you are between 16 and 24 years of age and require High Needs support without your Local Authority commissioning your place.</li>
                    <li>If you are 24 years of age or above and require Additional Learning Support, the support available will reflect the level of funding available.</li>
                    <li>If you are in receipt of additional learning support and absent from your course for two consecutive weeks without giving notice, support will be withdrawn.</li>
                    <li>You are responsible for finding out the dates, times, and venues of your exams, and must inform the College Exams office if you have a clash of exams, require access arrangements, or wish to withdraw.</li>
                    <li>The College reserves the right to terminate your enrolment if false, incomplete, or misleading information is provided.</li>
                    <li>The College reserves the right to decline any student who fails to answer the Safeguarding question on unspent/pending convictions on the enrolment form.</li>
                    <li>All courses are subject to enrolment and retention of sufficient numbers, and this may mean relocating to other Heart of Yorkshire Education Group premises if necessary.</li>
                    <li>The College accepts no liability for any loss or damage to your property while on its premises.</li>
                    <li>No fees are refundable if The College is unable to perform its obligations due to causes beyond its reasonable control, including natural disasters, adverse weather conditions, or other unforeseen events.</li>
                    <li>You are required to accept responsibility for any equipment loaned to you by The College and meet all costs for replacements or repairs if the equipment is lost, stolen, or damaged.</li>
                    <li>The College recognizes that you are the owner of any Intellectual Property Rights in the work you produce while registered as a student, except in cases of collaborative or externally sponsored work.</li>
                    <li>Some lectures and other activities may be photographed and/or published. Consent will be sought separately for usage of your likeness in such cases.</li>
                </ul>
            </section>

            <hr />

            <section>
                <h4>Section C: Data Protection</h4>
                <p>Data protection legislation gives you rights regarding the data collected about you and how it is used. By signing the Enrolment Form, you understand that The College has a public duty to collect your personal details for our Funding Agencies.</p>

                <h5>Privacy Notice - How We Use Your Personal Information</h5>
                <p>This privacy notice is issued by the Education and Skills Funding Agency (ESFA) on behalf of the Secretary of State for the Department of Education (DfE) to inform learners about the Individualised Learner Record (ILR) and how their personal information is used in the ILR. Your personal information is used by the DfE under the lawful basis of article 6(1)(e) of the UK GDPR.</p>

                <ul>
                    <li>The ILR collects data about learners and learning undertaken. Publicly funded colleges must collect and return this data to the ESFA each year.</li>
                    <li>We retain your ILR learner data for 20 years for operational purposes.</li>
                    <li>ILR data is shared with third parties where it complies with DfE data sharing procedures.</li>
                    <li>The DfE and the English European Social Fund (ESF) may contact learners to carry out research and evaluation.</li>
                </ul>

                <p>For more information about how your personal data is used and your individual rights, visit:</p>
                <ul>
                    <li><a href="https://www.gov.uk/government/collections/individualised-learner-record-ilr">ILR Specification</a></li>
                    <li><a href="https://www.gov.uk/government/organisations/department-for-education/about/personal-information-charter">DfE Personal Information Charter</a></li>
                    <li><a href="https://form.education.gov.uk/service/Contact_the_Department_for_Education">Contact the Department for Education</a></li>
                </ul>
                <p>If you are unhappy with how your personal data is used, you can complain to the Information Commissioner’s Office (ICO): <a href="https://www.ico.org.uk">https://www.ico.org.uk</a></p>
            </section>
        </div>
    );
};

export default YorkShirePolicy;
