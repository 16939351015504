import moment from "moment"

export const lmpProcessedData = (data) => {
    let gender
    if (data.personalDetails.gender === 'Female') {
        gender = 2
    } else {
        gender = 1
    }
    let ethnicity
    if (data.oppDetails.ethnicOrigin === 'English/Welsh/Scottish/Northern Irish/British') {
        ethnicity = '31'
    } else if (data.oppDetails.ethnicOrigin === 'Irish') {
        ethnicity = '32'
    } else if (data.oppDetails.ethnicOrigin === 'Gypsy or Irish traveller') {
        ethnicity = '33'
    } else if (data.oppDetails.ethnicOrigin === 'Any other white background') {
        ethnicity = '34'
    } else if (data.oppDetails.ethnicOrigin === 'White and Black Carribean') {
        ethnicity = '35'
    } else if (data.oppDetails.ethnicOrigin === 'White and Black African') {
        ethnicity = '36'
    } else if (data.oppDetails.ethnicOrigin === 'White and Asian') {
        ethnicity = '37'
    } else if (
        data.oppDetails.ethnicOrigin ===
        'Any other mixed/multiple ethnic background'
    ) {
        ethnicity = '38'
    } else if (data.oppDetails.ethnicOrigin === 'Indian') {
        ethnicity = '39'
    } else if (data.oppDetails.ethnicOrigin === 'Pakistani') {
        ethnicity = '40'
    } else if (data.oppDetails.ethnicOrigin === 'Bangladeshi') {
        ethnicity = '41'
    } else if (data.oppDetails.ethnicOrigin === 'Chinese') {
        ethnicity = '42'
    } else if (data.oppDetails.ethnicOrigin === 'Any other Asian background') {
        ethnicity = '43'
    } else if (data.oppDetails.ethnicOrigin === 'African') {
        ethnicity = '44'
    } else if (data.oppDetails.ethnicOrigin === 'Caribbean') {
        ethnicity = '45'
    } else if (
        data.oppDetails.ethnicOrigin === 'Any other Black/African/Caribbean'
    ) {
        ethnicity = '46'
    } else if (data.oppDetails.ethnicOrigin === 'Arab') {
        ethnicity = '47'
    } else if (data.oppDetails.ethnicOrigin === 'Any other ethnic group') {
        ethnicity = '98'
    }
    let title
    if (data.personalDetails.title === 'Mr') {
        title = 1
    } else if (data.personalDetails.title === 'Mrs') {
        title = 2
    } else if (data.personalDetails.title === 'Ms') {
        title = 4
    } else if (data.personalDetails.title === 'Miss') {
        title = 3
    } else if (data.personalDetails.title === 'Mx') {
        title = 8
    } else if (data.personalDetails.title === 'other') {
        title = 9
    }
    let preferedContact = []
    if (data.declaration?.prefContact1) {
        preferedContact.push('P')
    } else if (data.declaration?.prefContact) {
        preferedContact.push('E')
    } else if (data.declaration?.prefContact2) {
        preferedContact.push('TS')
    }
    let disability
    if (data.oppDetails.disabilities === 'Yes') {
        disability = 1
    } else {
        disability = 2
    }

    let disabilityCode = []
    if (data.oppDetails.dValue1) {
        disabilityCode.push('4')
        // form.getCheckBox('Visual impairment').check()
    }
    if (data.oppDetails.dValue2) {
        disabilityCode.push('5')
        // form.getCheckBox('Hearing impairment').check()
    }
    if (data.oppDetails.dValue3) {
        disabilityCode.push('6')
        // form.getCheckBox('Disability affecting mobility').check()
    }
    if (data.oppDetails.dValue4) {
        disabilityCode.push('7')
        // form.getCheckBox('Profound complex disabilities').check()
    }
    if (data.oppDetails.dValue5) {
        disabilityCode.push('8')
        // form.getCheckBox('Social and Emotional Difficulties').check()
    }
    if (data.oppDetails.dValue6) {
        disabilityCode.push('9')
        // form.getCheckBox('Mental health difficulty').check()
    }
    if (data.oppDetails.dValue7) {
        disabilityCode.push('10')
        // form.getCheckBox('Moderate difficulties').check()
    }
    if (data.oppDetails.dValue8) {
        disabilityCode.push('11')
        // form.getCheckBox('Severe difficulties').check()
    }
    if (data.oppDetails.dValue9) {
        disabilityCode.push('12')
        // form.getCheckBox('Dyslexia').check()
    }
    if (data.oppDetails.dValue10) {
        disabilityCode.push('13')
        // form.getCheckBox('Dyscalculia').check()
    }
    if (data.oppDetails.dValue11) {
        disabilityCode.push('14')
        // form.getCheckBox('Autism spectrum disorder').check()
    }
    if (data.oppDetails.dValue12) {
        disabilityCode.push('15')
        // form.getCheckBox('Aspergers Syndrome').check()
    }
    if (data.oppDetails.dValue13) {
        disabilityCode.push('16')
        // form.getCheckBox('Temporary disability after illness').check()
    }
    if (data.oppDetails.dValue14) {
        disabilityCode.push('17')
        // form.getCheckBox('Speech Language and').check()
    }
    if (data.oppDetails.dValue15) {
        disabilityCode.push('93')
        // form.getCheckBox('Other physical disability').check()
    }
    if (data.oppDetails.dValue16) {
        disabilityCode.push('95')
        // form.getCheckBox('Medical condition').check()
    }
    if (data.oppDetails.dValue17) {
        disabilityCode.push('94')
        // form.getCheckBox('learning-difficulty').check()
    }
    if (data.oppDetails.dValue18) {
        disabilityCode.push('97')
        // form.getCheckBox('Other').check()
    }

    let qualificaiton
    if (data.qualificationDetails.level === 'Entry Level') {
        qualificaiton = 99
    } else if (data.qualificationDetails.level === 'None') {
        qualificaiton = 1
    } else if (data.qualificationDetails.level === 'Level 1') {
        qualificaiton = 2
    } else if (data.qualificationDetails.level === 'Level 2') {
        qualificaiton = 3
    } else if (data.qualificationDetails.level === 'Level 3') {
        qualificaiton = 5
    } else if (data.qualificationDetails.level === 'Level 4') {
        qualificaiton = 7
    } else if (data.qualificationDetails.level === 'Level 5') {
        qualificaiton = 8
    } else if (data.qualificationDetails.level === 'Level 6') {
        qualificaiton = 9
    } else if (data.qualificationDetails.level === 'Level 7') {
        qualificaiton = 1
    } else if (data.qualificationDetails.level === 'Level 7 and above') {
        qualificaiton = 10
    } else if (data.qualificationDetails.level === 'Full Level 2') {
        qualificaiton = 4
    } else if (data.qualificationDetails.level === 'Full Level 3') {
        qualificaiton = 6
    }
    let startDate = moment(data.dateApproved);
    startDate.add(7, 'days');
    const resultStartDate = startDate.format('YYYY-MM-DD')


    let employmentStatus

    if (data.employmentDetails.employementStatus === "In paid employment (or self employed)" ||
        data.employmentDetails.employementStatus === "Employed but on less than £17,374.50 per year" ||
        data.employmentDetails.employementStatus === "Living in London - Employed or self employed" ||
        data.employmentDetails.employementStatus === "Greater London Employed but on less than £21,547") {
        employmentStatus = 10
    } else {
        if (data.employmentDetails.lookingForWork === "Yes") {
            employmentStatus = 11
        } else {
            employmentStatus = 12
        }
    }

    let employmentLength
    if (data.employmentDetails.length === 'Up to 3') {
        employmentLength = 1
    } else if (data.employmentDetails.length === '4-6') {
        employmentLength = 2
    } else if (data.employmentDetails.length === '7-12') {
        employmentLength = 3
    } else if (data.employmentDetails.length === '12+') {
        employmentLength = 4
    }

    let employmentHours

    if (data.employmentDetails.hoursPerWeek === "0 to 10 hours per week") {
        employmentHours = 5
    } else if (data.employmentDetails.hoursPerWeek === "11 to 20 hours per week") {
        employmentHours = 6
    } else if (data.employmentDetails.hoursPerWeek === "21 to 30 hours per week") {
        employmentHours = 7
    } else if (data.employmentDetails.hoursPerWeek === "30+ hours per week") {
        employmentHours = 8
    }



    let benefits
    if (data.employmentDetails.dValue1) {
        benefits = 1
    } else if (data.employmentDetails.dValue2) {
        benefits = 6
    } else if (data.employmentDetails.dValue3) {
        benefits = 6
    } else if (data.employmentDetails.dValue6) {
        benefits = 6
    } else if (data.employmentDetails.dValue5) {
        benefits = 5
    } else if (data.employmentDetails.dValue7) {
        benefits = 6
    } else if (data.employmentDetails.dValue9) {
        benefits = 6
    } else if (data.employmentDetails.dValue8) {
        benefits = 4
    }

    let unemploymentLength

    if (data.employmentDetails.unemployedLength === '0-5 months') {
        unemploymentLength = 1
    } else if (data.employmentDetails.unemployedLength === '6-11 months') {
        unemploymentLength = 2
    } else if (data.employmentDetails.unemployedLength === '12-23 months') {
        unemploymentLength = 3
    } else if (data.employmentDetails.unemployedLength === '24-35 months') {
        unemploymentLength = 4
    } else if (data.employmentDetails.unemployedLength === 'Over 36 months') {
        unemploymentLength = 5
    }

    let cob, countryOfBirth
    if (data.oppDetails.countryOfBirth === 'England' ||
        data.oppDetails.countryOfBirth === 'United Kingdom' ||
        data.oppDetails.countryOfBirth === 'Scotland' ||
        data.oppDetails.countryOfBirth === 'Wales' ||
        data.oppDetails.countryOfBirth === 'United Kingdom') {
        cob = 'Y'
    } else {
        cob = 'N'
        countryOfBirth = data.oppDetails.countryOfBirth
    }

    let criminalConv
    if (data.oppDetails.criminalConv === "Yes") {
        criminalConv = 'Y'
    } else {
        criminalConv = 'N'
    }
    const parsedDate = moment(data.personalDetails.dob);
    const dateOfBirth = parsedDate.format("DD/MM/YYYY")
    const addressParts = data.personalDetails.addLine1.split(', ');


    let course
    if (data.detailsFormData.appliedCourse === 'Mental Health Problems') {
        course = 13
    } else if (data.detailsFormData.appliedCourse === 'Information, Advice or Guidance') {
        course = 27
    } else if (data.detailsFormData.appliedCourse === 'Business Administration') {
        course = 5
    } else if (data.detailsFormData.appliedCourse === 'Customer Service') {
        course = 4
    } else if (data.detailsFormData.appliedCourse === 'Understanding Autism') {
        course = 8
    } else if (data.detailsFormData.appliedCourse === `Children and Young People's Mental Health`) {
        course = 14
    } else if (data.detailsFormData.appliedCourse === 'Counselling Skills') {
        course = 9
    } else if (data.detailsFormData.appliedCourse === 'Caring for children and young people') {
        course = 10
    } else if (data.detailsFormData.appliedCourse === 'Certificate in Nutrition and Health') {
        course = 41
    } else if (data.detailsFormData.appliedCourse === 'Dignity and Safeguarding in Adult Health and Social Care') {
        course = 23
    } else if (data.detailsFormData.appliedCourse === 'Dementia Care') {
        course = 18
    } else if (data.detailsFormData.appliedCourse === 'Team Leading') {
        course = 43
    } else if (data.detailsFormData.appliedCourse === 'End of Life Care') {
        course = 20
    } else if (data.detailsFormData.appliedCourse === 'Principles of Warehousing') {
        course = 26
    } else if (data.detailsFormData.appliedCourse === 'Understanding Specific Learning Difficulties') {
        course = 42
    }

    return [
        data.personalDetails.firstName,
        data.personalDetails.firstName,
        data.personalDetails.lastName,
        gender || '',
        ethnicity || '',
        title || '',
        dateOfBirth,
        data.personalDetails.nationalInsNo,
        '',
        '',
        "Learner Find*LearnerFinder*",
        addressParts[0],
        addressParts[1],
        addressParts[2],
        addressParts[3],
        data.personalDetails.postcode,
        data.personalDetails.telephone,
        data.personalDetails.telephone,
        data.personalDetails.email,
        data.emergencyDetails.emergencyContactName,
        data.emergencyDetails.relationshipToLearner,
        data.emergencyDetails.emergencyTelephone,
        "",
        "",
        "ETS",
        preferedContact.toString() || 'E',
        "",
        disability || '',
        (disabilityCode.join()).replace(/,/g, '*') || '',
        '',
        qualificaiton || '',
        resultStartDate || '',
        employmentStatus || '',
        employmentLength || '',
        employmentHours || '',
        benefits || '',
        unemploymentLength || '',
        course,
        "",
        "",
        "",
        data.employmentDetails.hoursPerWeek || '',
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        cob || '',
        countryOfBirth || '',
        "Y",
        '',
        '',
        data.oppDetails.perField || '',
        "",
        "Y",
        "",
        criminalConv || '',
        "",
        ""
    ]

}